<template>
  <div>
    <div v-if="loading">
      Loading...
    </div>

    <div v-else>
      <Bar :data="chartData" :options="chartOptions" />
      <div v-for="m in measurements" :key="m.timestamp">
        <div class="data-entry">{{ this.formatHourTimestamp(m.timestamp) }}: {{ (m.value / 100).toFixed(2) }}kg</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import { Bar } from 'vue-chartjs'
const API_URL = process.env.VUE_APP_API || `http://localhost:3001`;

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default {
  name: 'measurements-chart',
  components: { Bar },
  data() {
    return {
      chartData: {
        labels: [],
        datasets: []
      },
      chartOptions: {
        plugins: {
          title: {
            display: true
          },
          legend: {
            display: false
          }
        },
        elements: {
          bar: {
            backgroundColor: this.colorize()
          }
        },
        responsive: true,
        legend: {
          show: false
        },
        scales: {
          y: {
            min: 25,
            ticks: {
              callback: (value) => `${value}kg`
            }
          }
        }
      },
      loading: false,
      fromDate: null,
      toDate: null,
      measurements: [],
      dates: []
    }
  },

  methods: {
    colorize() {
      return (ctx) => {
        const label = ctx.parsed.x;
        if (this.dates.indexOf(this.chartData.labels[label].substr(0, 10)) % 2 === 0) {
          return '#D95A24'
        }
        return '#F3CB3C'
      }
    },

    formatHourTimestamp(hourTimestamp) {
      const date = new Date(`${hourTimestamp}:00:00.000Z`);

      return `${date.getFullYear()}-${(date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${(date.getDate() + 1) < 10 ? `0${date.getDate() + 1}` : date.getDate() + 1}
       ${(date.getHours() + 1) < 10 ? `0${date.getHours() + 1}` : date.getHours() + 1}:00:00
      `
    },
    isDateValid(dateString) {
      return (new Date(dateString).toString() !== 'Invalid Date')
    },
    fetchData() {
      this.loading = true;

      const { from, to, period } = this.$route.query;
      this.fromDate = new Date();
      this.toDate = new Date();
      if (this.isDateValid(from)) {
        this.fromDate = new Date(from);
      }

      if (this.isDateValid(to)) {
        this.toDate = new Date(to)
      }

      axios.post(`${API_URL}/measurements/graphql/asdf`, {
        query: `{measurementsInRangeByPeriod(mac: "7C:9E:BD:F6:58:2C",from: "${this.fromDate.toISOString()}", to: "${this.toDate.toISOString()}", period: "${period}") { value, timestamp}}`
      }).then(res => {
        this.measurements = JSON.parse(res.data[0]).data.measurementsInRangeByPeriod
        this.chartOptions.plugins.title.text = `${this.fromDate.toDateString()} - ${this.toDate.toDateString()}`
        this.chartData.labels = this.measurements.map(x => x.timestamp)
        this.chartData.datasets = [{
          data: this.measurements.map(x => x.value / 100)
        }]
        this.dates = Object.keys(this.measurements.reduce((prev, current) => {
          const date = current.timestamp.substr(0, 4 + 2 + 4)
          if (!prev[date]) {
            prev[date] = {
              backgroundColor: '#ff00aa',
              label: date,
              data: []
            }
          }
          prev[date].data.push(current.value / 100)
          return prev;
        }, {}))
      }).catch(e => {
        console.log(e);
      }).finally(() => {
        this.loading = false
      })
    }
  },

  mounted() {
    this.fetchData();
  }

}
</script>
<style scoped>
.data-entry {
  font-size: x-small;
}
</style>