<template>
  <div>
    <div class="measurement">
      <div>Waga: {{ (weight.value / 100).toFixed(2) }}kg</div>
      <div class="timestamp">{{ weight.timestamp }}</div>
    </div>
    <div class="measurement">
      <div>Temperatura: {{ extTemperature.value }}°C</div>
      <div class="timestamp">{{ extTemperature.timestamp }}</div>
    </div>
    <div class="measurement">
      <div>Temperatura wew.: {{ temperature.value }}°C</div>
      <div class="timestamp">{{ temperature.timestamp }}</div>
    </div>
    <div class="measurement">
      <div>Wilgotność: {{ humidity.value }}%</div>
      <div class="timestamp">{{ humidity.timestamp }}</div>
    </div>
    <div class="measurement">
      <div>Ciśnienie: {{ pressure.value }}hPa</div>
      <div class="timestamp">{{ pressure.timestamp }}</div>
    </div>
    <button :disabled='!forceAvailable' v-on:click="requestMeasurements">Inicjuj odczyt</button>
    <button :disabled='!tareAvailable' v-on:click="requestTareDevice">Taruj</button>
  </div>
</template>

<script>
import axios from 'axios'
const API_URL = process.env.VUE_APP_API || `http://localhost:3001`;
const WS_URL = process.env.VUE_APP_WS || `ws://localhost:3001`;

export default {
  name: 'App',
  data: () => {
    return {
      connection: null,
      forceAvailable: true,
      tareAvailable: true,
      weight: { value: 0, timestamp: '' },
      temperature: { value: 0, timestamp: '' },
      extTemperature: { value: 0, timestamp: '' },
      humidity: { value: 0, timestamp: '' },
      pressure: { value: 0, timestamp: '' }
    }
  },

  methods: {
    fetchMeasurements() {
      fetch(`${API_URL}/measurements/7C:9E:BD:F6:58:2C`)
        .then(async res => {
          const data = await res.json();
          data.forEach(measurement => {
            this[measurement.type].value = measurement.value
            this[measurement.type].timestamp = measurement.timestamp
          })
        })
        .catch(e => console.log(e))
    },

    requestMeasurements() {
      axios(`${API_URL}/measurementRequests/7C:9E:BD:F6:58:2C`, { method: "POST" })
        .then(() => this.forceAvailable = false)
        .catch(e => console.log(e));
    },

    requestTareDevice() {
      axios(`${API_URL}/tareRequests/7C:9E:BD:F6:58:2C`, { method: "POST" })
        .then(() => this.tareAvailable = false)
        .catch(e => console.log(e));
    }
  },

  created() {
    this.fetchMeasurements()
    this.connection = new WebSocket(`${WS_URL}/measurements-ws`)

    this.connection.onmessage = event => {
      const data = JSON.parse(event.data);

      data.forEach(measurement => {
        this[measurement.type].value = measurement.value
        this[measurement.type].timestamp = measurement.timestamp
      })

      this.forceAvailable = true
    }
  }
}
</script>

<style>
.timestamp {
  font-size: xx-small;
}

.measurement {
  padding: 0.2rem;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 10px;
  font-size: 3rem;
}
</style>
